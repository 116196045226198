<template>
  <el-dialog :visible.sync="dialogFormVisible" :show-close="false" width="600px">
    <div slot="title" class="left_top">
      <div class="left_icon">
        <span></span>
        <span>新增自定义用户</span>
      </div>
    </div>
    <el-form
      ref="form"
      :model="form"
      :rules="ruleForm"
      label-position="top"
      style="text-align:left"
    >
      <el-form-item label="用户名" prop="name">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="roles">
        <el-select v-model="form.roles" placeholder="请选择角色" style="width:100%">
          <el-option
            v-for="item in rolesList"
            :key="item.alias"
            :label="item.name"
            :value="item.alias"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联企业" prop="enterprise">
        <el-select style="width: 100%" v-model="form.enterprise" filterable clearable>
          <el-option
            v-for="(item, index) in enterpriseList"
            :key="index"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号 ( 用于找回密码 )" prop="username">
        <el-input
          v-model="form.username"
          type="text"
          autocomplete="off"
          readonly
          onfocus="this.removeAttribute('readonly');"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          v-model="form.password"
          type="password"
          autocomplete="off"
          readonly
          onfocus="this.removeAttribute('readonly');"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="扩展参数" prop="extends">
        <el-input v-model="form.extends" type="textarea" autocomplete="off">
        </el-input>
      </el-form-item>-->
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align:center">
      <el-button type="primary" @click="submit('form')">确 定</el-button>
      <el-button @click="closeDialog('form')">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { phoneReg } from '../../lib/global';
import { api } from '../../api';
export const userAddCustomerUser = api()('user.add.customerUser.json');
// 查询企业简单列表
export const enterpriseSimpleList = api()('enterprise.simple.list.json');
export default {
  props: {
    dialogFormVisible: Boolean
  },
  data () {
    var checkPhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号码！'));
      } else if (!phoneReg.test(value)) {
        callback(new Error('请输入正确的手机号码！'));
      }
      callback();
    };
    return {
      enterpriseList: [],
      form: {
        name: '',
        roles: '',
        enterprise: '',
        username: '',
        password: '',
        extends: ''
      },
      ruleForm: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        roles: [
          { required: true, message: '请输入选择角色', trigger: 'change' }
        ],
        enterprise: [
          { required: false, message: '请输入选择关联企业', trigger: 'change' }
        ],
        username: [
          {
            validator: checkPhone,
            required: true,
            trigger: ['change', 'blur']
          }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        extends: [{ required: true, message: '请输入参数', trigger: 'blur' }]
      },
      rolesList: []
    };
  },
  created () {
    this.getenterpriseList();
    this.rolesList = this.$route.params.$preload.roleList.filter(
      it => it.type === 'custom'
    );
  },
  methods: {
    async getenterpriseList () {
      const res = await enterpriseSimpleList({
        pageNumber: 1,
        pageSize: 10000
      });
      this.enterpriseList = res.list;
    },
    closeDialog (form) {
      this.$refs[form].resetFields();
      this.$emit('update:dialogFormVisible', false);
      this.$emit('close');
    },
    submit (form) {
      this.$refs[form].validate(async isValid => {
        if (isValid) {
          await userAddCustomerUser(this.form);
          this.$message.success('创建成功！');
          this.closeDialog(form);
        }
      });
    }
  }
};
</script>
